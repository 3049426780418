@media screen and (max-width: 1650px) {
  .container-inner {
    .cus-top-con {
      h2 {
        font-size: 35px;
      }
      p {
        font-size: 18px;
      }
    }
  }
  .cus-logo-sec {
    .logo_image {
      height: 80px;
    }
  }
  .spin-button {
    button {
      font-size: 16px;
    }
  }
  .cus-circle-inner {
    .circle_grid {
      width: 280px;
      height: 280px;
      .circle-icon {
        img {
          width: 80px;
          height: 80px;
        }
      }
      h2 {
        font-size: 18px;
      }
    }
    .spin-button {
      button {
        width: 260px;
      }
    }
  }
  .form-container-inner {
    .form-container-con {
      .form_Container {
        .form-heading {
          h2 {
            font-size: 26px;
          }
          h5 {
            font-size: 16px;
          }
        }
        .login_button {
          font-size: 18px;
        }
        .forgot-text {
          font-size: 14px;
        }
      }
    }
  }
}
@media screen and (max-width: 1279px) {
  .cus-circle-inner {
    .circle_grid {
      width: 240px;
      height: 240px;
      margin: 10px auto;
      .circle-icon {
        img {
          width: 70px;
          height: 70px;
        }
      }
      h2 {
        font-size: 16px;
      }
    }
    .spin-button {
      button {
        width: 220px;
      }
    }
  }
}
@media screen and (max-width: 959px) {
  .cus-circle-inner {
    margin-bottom: 25px;
    .circle_grid {
      width: 200px;
      height: 200px;
      .circle-icon {
        img {
          width: 60px;
          height: 60px;
        }
      }
      h2 {
        font-size: 14px;
      }
    }
    .spin-button {
      margin: 10px 0;
      button {
        width: 200px;
      }
    }
  }
  .container-inner {
    .cus-top-con {
      h2 {
        font-size: 30px;
      }
      p {
        font-size: 16px;
      }
    }
  }
}
@media screen and (max-width: 599px) {
  .form-container-inner {
    .form-container-con {
      .form_Container {
        padding: 40px 6% 20px 6%;
        .cus-circle-logo {
          img {
            width: 80px;
          }
          &::after {
            width: 140px;
            height: 80px;
            top: -30px;
          }
        }
        .form-heading {
          h2 {
            font-size: 24px;
            margin-bottom: 10px;
          }
          h5 {
            font-size: 14px;
          }
        }
        .login_button {
          font-size: 16px;
        }
      }
    }
  }
  .main_container {
    padding: 30px 15px;
  }
  .login-bottom-logo {
    .login_logo_image {
      width: 120px;
    }
  }
  .cus-circle-inner {
    .circle_grid {
      width: 190px;
      height: 190px;
      .circle-icon {
        margin-bottom: 15px;
        img {
          width: 55px;
          height: 55px;
        }
      }
    }
    .spin-button {
      button {
        width: 190px;
      }
    }
  }
  .container-inner {
    .cus-top-con {
      h2 {
        font-size: 26px;
      }
      p {
        font-size: 14px;
      }
    }
  }
  .spin-button {
    button {
      width: 100%;
      max-width: 350px;
    }
  }
  .spinner-div {
    width: 400px;
    height: 400px;
    .wheel {
      width: 24em;
      height: 24em;
      li {
        .text {
          font-size: 9px;
        }
        .image {
          width: 30px;
          height: 20px;
        }
        &:first-child {
          .image {
            left: 25px;
            top: 110px;
          }
        }
        &:nth-child(2) {
          .image {
            left: 23px;
            top: 105px;
          }
        }
        &:nth-child(3) {
          .image {
            left: 21px;
            top: 104px;
          }
        }
        &:nth-child(4) {
          .image {
            left: 19px;
            top: 106px;
          }
        }
        &:nth-child(5) {
          .image {
            left: 24px;
            top: 104px;
          }
        }
        &:nth-child(6) {
          .image {
            left: 23px;
            top: 104px;
          }
        }
        &:nth-child(7) {
          .image {
            left: 23px;
            top: 103px;
          }
        }
        &:nth-child(8) {
          .image {
            left: 22px;
            top: 103px;
          }
        }
        &:nth-child(9) {
          .image {
            left: 22px;
            top: 102px;
          }
        }
        &:nth-child(10) {
          .image {
            left: 22px;
            top: 98px;
          }
        }
        &:nth-child(11) {
          .image {
            left: 21px;
            top: 98px;
          }
        }
        &:nth-child(12) {
          .image {
            left: 21px;
            top: 99px;
          }
        }
        &.selected-list {
          &::after {
            bottom: 55px;
          }
        }
      }
    }
    .spin-center-second {
      width: 170px;
      height: 170px;
    }
  }
  .cus-logo-sec {
    .logo_image {
      &.logo-screen {
        height: 250px;
      }
    }
  }
}
@media screen and (max-width: 420px) {
  .spinner-div {
    width: 280px;
    height: 280px;
    .wheel {
      width: 17em;
      height: 17em;
      border: 4px solid $black-color;
      li {
        .text {
          font-size: 6px;
          padding-top: 10px;
        }
        .image {
          width: 20px;
          height: 14px;
        }
        &:first-child {
          .image {
            left: 17px;
            top: 84px;
          }
        }
        &:nth-child(2) {
          .image {
            left: 16px;
            top: 78px;
          }
        }
        &:nth-child(3) {
          .image {
            left: 16px;
            top: 78px;
          }
        }
        &:nth-child(4) {
          .image {
            left: 15px;
            top: 80px;
          }
        }
        &:nth-child(5) {
          .image {
            left: 16px;
            top: 76px;
          }
        }
        &:nth-child(6) {
          .image {
            left: 16px;
            top: 80px;
          }
        }
        &:nth-child(7) {
          .image {
            left: 16px;
            top: 73px;
          }
        }
        &:nth-child(8) {
          .image {
            left: 16px;
            top: 74px;
          }
        }
        &:nth-child(9) {
          .image {
            left: 16px;
            top: 75px;
          }
        }
        &:nth-child(10) {
          .image {
            left: 16px;
            top: 76px;
          }
        }
        &:nth-child(11) {
          .image {
            left: 14px;
            top: 80px;
          }
        }
        &:nth-child(12) {
          .image {
            left: 16px;
            top: 84px;
          }
        }
        &.selected-list {
          &::after {
            bottom: 40px;
            left: -67px;
          }
        }
      }
    }
    .spin-center-second {
      width: 120px;
      height: 120px;
    }
  }
  .spin-button {
    button {
      max-width: 260px;
    }
  }
  .cus-logo-sec {
    .logo_image {
      &.logo-screen {
        height: 200px;
      }
    }
  }
}
