@font-face {
  font-family: "Proxima Nova Alt Lt";
  src: url("../fonts/proxima/ProximaNovaA-Light.eot");
  src: url("../fonts/proxima/ProximaNovaA-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/proxima/ProximaNovaA-Light.woff2") format("woff2"),
    url("../fonts/proxima/ProximaNovaA-Light.woff") format("woff"),
    url("../fonts/proxima/ProximaNovaA-Light.ttf") format("truetype"),
    url("../fonts/proxima/ProximaNovaA-Light.svg#ProximaNovaA-Light")
      format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova Alt Th";
  src: url("../fonts/proxima/ProximaNovaA-Thin.eot");
  src: url("../fonts/proxima/ProximaNovaA-Thin.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/proxima/ProximaNovaA-Thin.woff2") format("woff2"),
    url("../fonts/proxima/ProximaNovaA-Thin.woff") format("woff"),
    url("../fonts/proxima/ProximaNovaA-Thin.ttf") format("truetype"),
    url("../fonts/proxima/ProximaNovaA-Thin.svg#ProximaNovaA-Thin")
      format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova Alt Rg";
  src: url("../fonts/proxima/ProximaNovaA-Bold.eot");
  src: url("../fonts/proxima/ProximaNovaA-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/proxima/ProximaNovaA-Bold.woff2") format("woff2"),
    url("../fonts/proxima/ProximaNovaA-Bold.woff") format("woff"),
    url("../fonts/proxima/ProximaNovaA-Bold.ttf") format("truetype"),
    url("../fonts/proxima/ProximaNovaA-Bold.svg#ProximaNovaA-Bold")
      format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova Bl";
  src: url("../fonts/proxima/ProximaNova-Black.eot");
  src: url("../fonts/proxima/ProximaNova-Black.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/proxima/ProximaNova-Black.woff2") format("woff2"),
    url("../fonts/proxima/ProximaNova-Black.woff") format("woff"),
    url("../fonts/proxima/ProximaNova-Black.ttf") format("truetype"),
    url("../fonts/proxima/ProximaNova-Black.svg#ProximaNova-Black")
      format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova Rg";
  src: url("../fonts/proxima/ProximaNova-Bold.eot");
  src: url("../fonts/proxima/ProximaNova-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/proxima/ProximaNova-Bold.woff2") format("woff2"),
    url("../fonts/proxima/ProximaNova-Bold.woff") format("woff"),
    url("../fonts/proxima/ProximaNova-Bold.ttf") format("truetype"),
    url("../fonts/proxima/ProximaNova-Bold.svg#ProximaNova-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova Th";
  src: url("../fonts/proxima/ProximaNova-Extrabld.eot");
  src: url("../fonts/proxima/ProximaNova-Extrabld.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/proxima/ProximaNova-Extrabld.woff2") format("woff2"),
    url("../fonts/proxima/ProximaNova-Extrabld.woff") format("woff"),
    url("../fonts/proxima/ProximaNova-Extrabld.ttf") format("truetype"),
    url("../fonts/proxima/ProximaNova-Extrabld.svg#ProximaNova-Extrabld")
      format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova Th";
  src: url("../fonts/proxima/ProximaNovaT-Thin.eot");
  src: url("../fonts/proxima/ProximaNovaT-Thin.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/proxima/ProximaNovaT-Thin.woff2") format("woff2"),
    url("../fonts/proxima/ProximaNovaT-Thin.woff") format("woff"),
    url("../fonts/proxima/ProximaNovaT-Thin.ttf") format("truetype"),
    url("../fonts/proxima/ProximaNovaT-Thin.svg#ProximaNovaT-Thin")
      format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova Rg";
  src: url("../fonts/proxima/ProximaNova-Regular.eot");
  src: url("../fonts/proxima/ProximaNova-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/proxima/ProximaNova-Regular.woff2") format("woff2"),
    url("../fonts/proxima/ProximaNova-Regular.woff") format("woff"),
    url("../fonts/proxima/ProximaNova-Regular.ttf") format("truetype"),
    url("../fonts/proxima/ProximaNova-Regular.svg#ProximaNova-Regular")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}

body {
  font-family: "Proxima Nova Rg", sans-serif;
}

.disabled {
  opacity: 0.3;
  cursor: default;
  pointer-events: none;
}

.main_container {
  background-color: $white-color;
  background-image: url("/assets/images/bg.png");
  height: 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px;
}
.spin-button {
  width: 100%;
  display: inline-block;
  text-align: center;
  margin: 20px 0;
  button {
    color: $white-color;
    background: $red-color;
    border: 1px solid $red-color;
    border-radius: 10px;
    box-shadow: none;
    outline: none;
    padding: 15px 30px;
    width: 350px;
    font-size: 18px;
    cursor: pointer;
    font-weight: bold;
    &:hover,
    &:focus {
      background: $red-dark-color;
      border: 1px solid $red-dark-color;
    }
  }
}
.spin-button-logout {
  width: 100%;
  display: inline-block;
  text-align: center;
  margin: 20px 0;
  button {
    color: $white-color;
    background: $red-color;
    border: 1px solid $red-color;
    border-radius: 10px;
    box-shadow: none;
    outline: none;
    padding: 15px 30px;
    font-size: 18px;
    cursor: pointer;
    font-weight: bold;
    float: right;
    &:hover,
    &:focus {
      background: $red-dark-color;
      border: 1px solid $red-dark-color;
    }
  }
}
.cus-logo-sec {
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  .logo_image {
    width: auto;
    height: 100px;
    &.logo-screen {
      height: 320px;
      margin-top: 10%;
      -webkit-transform: rotate(3deg);
      -moz-animation: rotate(3deg);
      -ms-animation: rotate(3deg);
      -o-animation: rotate(3deg);
      transform: rotate(3deg);
    }
  }

  + .container-inner {
    min-height: calc(100vh - 200px);
  }
}
.start-rotate {
  animation: start-rotate 1s linear infinite;
  font-weight: 600;
}
@keyframes start-rotate {
  100% {
    transform: rotate(360deg);
  }
}
.selected_list {
  -webkit-text-emphasis-style: triangle;
  margin: -19px;
  border: 9px solid $white-color;
}
.stop-rotate {
  animation-play-state: paused;
  font-weight: 600;
}
.gifts_boxes {
  width: 400px;
  height: 300px;
  background: $red-color;
  border-radius: 21px;
  opacity: 1;
  margin: auto;
  margin-top: 50px;
}
.cus-circle-inner {
  width: 100%;
  display: inline-block;
  .circle_grid {
    width: 300px;
    height: 300px;
    position: relative;
    padding: 0;
    margin: 1em auto;
    border-radius: 50%;
    list-style: none;
    overflow: hidden;
    background: $white-color;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .circle-icon {
      width: 100%;
      display: inline-block;
      text-align: center;
      margin-bottom: 20px;
      img {
        width: 100px;
        height: 100px;
      }
    }
    h2 {
      text-align: center;
      font-size: 22px;
      letter-spacing: 0px;
      color: $grey-color;
    }
  }
  .spin-button {
    button {
      width: 280px;
    }
  }
}
.form-container-inner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: calc(100vh - 60px);
  .form-container-con {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 80px;
    .form_Container {
      background-color: $white-color;
      margin: auto;
      height: auto;
      max-width: 720px;
      border-radius: 14px;
      padding: 80px 6% 50px 6%;
      position: relative;
      .cus-circle-logo {
        width: 100%;
        display: inline-block;
        text-align: center;
        position: absolute;
        top: -50px;
        left: 50%;
        -ms-transform: translate(-50%, 0);
        -webkit-transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0);
        -o-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
        img {
          width: 100px;
        }
        &::after {
          content: "";
          background-image: url(../images/outer-circle.png);
          width: 170px;
          height: 90px;
          position: absolute;
          left: 50%;
          top: -40px;
          -ms-transform: translate(-50%, 0);
          -webkit-transform: translate(-50%, 0);
          -moz-transform: translate(-50%, 0);
          -o-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
          background-repeat: no-repeat;
          background-position: top;
          background-size: cover;
          opacity: 0.5;
        }
      }
      .form-heading {
        width: 100%;
        display: inline-block;
        text-align: center;
        margin-bottom: 32px;
        h2 {
          font-size: 30px;
          font-weight: bold;
          color: $blue-color;
          margin-bottom: 12px;
        }
        h5 {
          font-size: 20px;
          font-weight: bold;
          color: $blue-color;
        }
      }
      .cus-field-rep {
        margin-bottom: 15px;
      }
      .forgot-text {
        float: right;
        width: auto;
        text-align: left;
        font-size: 16px;
        font-weight: 600;
        color: $blue-color;
        cursor: pointer;
      }
      .login_button {
        width: 100%;
        color: $white-color;
        background: $red-color;
        border-radius: 4px;
        font-size: 20px;
        border: 1px solid $red-color;
        padding: 10px;
        cursor: pointer;
        font-weight: bold;
        text-transform: none;
        height: 56px;
        &:hover,
        &:focus {
          background: $red-dark-color;
          border: 1px solid $red-dark-color;
        }
      }
    }
  }
}
.login-bottom-logo {
  width: 100%;
  display: inline-block;
  text-align: center;
  margin-top: 40px;
  .login_logo_image {
    width: 150px;
    height: auto;
  }
}
.circle_image {
  height: inherit;
}
.container-inner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: calc(100vh - 60px);
  .cus-top-con {
    width: 100%;
    display: inline-block;
    text-align: center;
    margin-bottom: 20px;
    h2 {
      font-size: 46px;
      font-weight: bold;
      color: $red-color;
      margin-bottom: 10px;
    }
    p {
      font-size: 24px;
      font-weight: normal;
      color: $white-color;
    }
  }
  .main {
    position: relative;
    margin: 0 auto;
  }
}
.spin:active {
  width: 70px;
  height: 70px;
  font-size: 20px;
  background: $yellow-color;
}
.next-btn {
  position: absolute;
  right: 15px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
  p {
    width: 100px;
    display: inline-block;
    text-align: center;
    padding: 10px 20px;
    background-color: $blue-color;
    color: $white-color;
    text-transform: uppercase;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
  }
}
.spinner-div {
  position: relative;
  width: 500px;
  height: 500px;
  background-image: url("../images/white-circle.svg");
  background-repeat: no-repeat;
  border-radius: 50%;
  background-position: center center;
  background-size: cover;
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  .wheel {
    position: relative;
    padding: 0;
    margin: 1em auto;
    width: 30em;
    height: 30em;
    border-radius: 50%;
    list-style: none;
    overflow: hidden;

    border: 8px solid $black-color;
    li {
      overflow: hidden;
      position: absolute;
      top: 0;
      right: 0;
      width: 50%;
      height: 50%;
      transform-origin: 0% 100%;
      &.fade-list {
        opacity: 0.3;
      }
      .text {
        position: absolute;
        left: -100%;
        width: 200%;
        height: 200%;
        text-align: center;
        transform: skewY(60deg) rotate(16deg);
        padding-top: 8px;
        font-size: 13px;
        color: #ffffff;
        font-weight: 600;
        -webkit-text-stroke: 0.3px #000000;
        -webkit-text-fill-color: white;

        &.text-middle {
          padding-top: 40px;
        }
      }
      &:first-child {
        transform: rotate(0deg) skewY(-60deg);
        .text {
          background: hsla(224, 55%, 39%, 1);
          background: linear-gradient(
            90deg,
            hsla(224, 55%, 39%, 1) 0%,
            hsla(219, 51%, 46%, 1) 100%
          );
          background: -moz-linear-gradient(
            90deg,
            hsla(224, 55%, 39%, 1) 0%,
            hsla(219, 51%, 46%, 1) 100%
          );
          background: -webkit-linear-gradient(
            90deg,
            hsla(224, 55%, 39%, 1) 0%,
            hsla(219, 51%, 46%, 1) 100%
          );
        }
        .image {
          transform: rotate(0deg) skewY(60deg);
          left: 22px;
          top: 110px;
          width: 40px;
          height: 38px;
        }
      }
      &:nth-child(2) {
        transform: rotate(30deg) skewY(-60deg);
        .text {
          background: hsla(285, 39%, 44%, 1);
          background: linear-gradient(
            90deg,
            hsla(285, 39%, 44%, 1) 0%,
            hsla(285, 39%, 44%, 1) 100%
          );
          background: -moz-linear-gradient(
            90deg,
            hsla(285, 39%, 44%, 1) 0%,
            hsla(285, 39%, 44%, 1) 100%
          );
          background: -webkit-linear-gradient(
            90deg,
            hsla(285, 39%, 44%, 1) 0%,
            hsla(285, 39%, 44%, 1) 100%
          );
        }
        .image {
          transform: rotate(1deg) skewY(60deg);
          left: 22px;
          top: 110px;
          width: 40px;
          height: 36px;
        }
      }
      &:nth-child(3) {
        transform: rotate(60deg) skewY(-60deg);
        .text {
          background: hsla(310, 41%, 48%, 1);
          background: linear-gradient(
            90deg,
            hsla(310, 41%, 48%, 1) 0%,
            hsla(311, 67%, 39%, 1) 100%
          );
          background: -moz-linear-gradient(
            90deg,
            hsla(310, 41%, 48%, 1) 0%,
            hsla(311, 67%, 39%, 1) 100%
          );
          background: -webkit-linear-gradient(
            90deg,
            hsla(310, 41%, 48%, 1) 0%,
            hsla(311, 67%, 39%, 1) 100%
          );
        }
        .image {
          transform: rotate(9deg) skewY(54deg);
          top: 122px;
          left: 22px;
          width: 52px;
          height: 30px;
        }
      }
      &:nth-child(4) {
        transform: rotate(90deg) skewY(-60deg);
        .text {
          background: hsla(355, 86%, 46%, 1);
          background: linear-gradient(
            90deg,
            hsla(355, 86%, 46%, 1) 0%,
            hsla(355, 73%, 51%, 1) 100%
          );
          background: -moz-linear-gradient(
            90deg,
            hsla(355, 86%, 46%, 1) 0%,
            hsla(355, 73%, 51%, 1) 100%
          );
          background: -webkit-linear-gradient(
            90deg,
            hsla(355, 86%, 46%, 1) 0%,
            hsla(355, 73%, 51%, 1) 100%
          );
        }
        .image {
          transform: rotate(5deg) skewY(58deg);
          top: 122px;
          left: 26px;
          width: 46px;
          height: 42px;
        }
      }
      &:nth-child(5) {
        transform: rotate(120deg) skewY(-60deg);
        .text {
          background: hsla(28, 91%, 54%, 1);
          background: linear-gradient(
            90deg,
            hsla(28, 91%, 54%, 1) 0%,
            hsla(28, 92%, 56%, 1) 100%
          );
          background: -moz-linear-gradient(
            90deg,
            hsla(28, 91%, 54%, 1) 0%,
            hsla(28, 92%, 56%, 1) 100%
          );
          background: -webkit-linear-gradient(
            90deg,
            hsla(28, 91%, 54%, 1) 0%,
            hsla(28, 92%, 56%, 1) 100%
          );
        }
        .image {
          transform: rotate(1deg) skewY(63deg);
          top: 118px;
          left: 28px;
          width: 42px;
          height: 42px;
        }
      }
      &:nth-child(6) {
        transform: rotate(150deg) skewY(-60deg);
        .text {
          background: hsla(48, 93%, 47%, 1);
          background: linear-gradient(
            90deg,
            hsla(48, 93%, 47%, 1) 0%,
            hsla(48, 93%, 47%, 1) 100%
          );
          background: -moz-linear-gradient(
            90deg,
            hsla(48, 93%, 47%, 1) 0%,
            hsla(48, 93%, 47%, 1) 100%
          );
          background: -webkit-linear-gradient(
            90deg,
            hsla(48, 93%, 47%, 1) 0%,
            hsla(48, 93%, 47%, 1) 100%
          );
        }
        .image {
          transform: rotate(4deg) skewY(59deg);
          top: 120px;
          left: 14px;
          width: 60px;
          height: 35px;
        }
      }
      &:nth-child(7) {
        transform: rotate(180deg) skewY(-60deg);
        .text {
          background: hsla(57, 93%, 52%, 1);
          background: linear-gradient(
            90deg,
            hsla(57, 93%, 52%, 1) 0%,
            hsla(57, 92%, 62%, 1) 100%
          );
          background: -moz-linear-gradient(
            90deg,
            hsla(57, 93%, 52%, 1) 0%,
            hsla(57, 92%, 62%, 1) 100%
          );
          background: -webkit-linear-gradient(
            90deg,
            hsla(57, 93%, 52%, 1) 0%,
            hsla(57, 92%, 62%, 1) 100%
          );
        }
        .image {
          transform: rotate(20deg) skewY(42deg);
          top: 130px;
          left: 10px;
          height: 30px;
          width: 70px;
        }
      }
      &:nth-child(8) {
        transform: rotate(210deg) skewY(-60deg);
        .text {
          background: hsla(72, 62%, 59%, 1);
          background: linear-gradient(
            90deg,
            hsla(72, 62%, 59%, 1) 0%,
            hsla(74, 61%, 52%, 1) 100%
          );
          background: -moz-linear-gradient(
            90deg,
            hsla(72, 62%, 59%, 1) 0%,
            hsla(74, 61%, 52%, 1) 100%
          );
          background: -webkit-linear-gradient(
            90deg,
            hsla(72, 62%, 59%, 1) 0%,
            hsla(74, 61%, 52%, 1) 100%
          );
        }
        .image {
          transform: rotate(9deg) skewY(51deg);
          top: 124px;
          left: 24px;
          width: 40px;
          height: 30px;
        }
      }
      &:nth-child(9) {
        transform: rotate(240deg) skewY(-60deg);
        .text {
          background: hsla(104, 42%, 46%, 1);
          background: linear-gradient(
            90deg,
            hsla(104, 42%, 46%, 1) 0%,
            hsla(99, 41%, 48%, 1) 100%
          );
          background: -moz-linear-gradient(
            90deg,
            hsla(104, 42%, 46%, 1) 0%,
            hsla(99, 41%, 48%, 1) 100%
          );
          background: -webkit-linear-gradient(
            90deg,
            hsla(104, 42%, 46%, 1) 0%,
            hsla(99, 41%, 48%, 1) 100%
          );
        }
        .image {
          transform: rotate(15deg) skewY(47deg);
          top: 130px;
          left: 18px;
          width: 64px;
          height: 34px;
        }
      }
      &:nth-child(10) {
        transform: rotate(270deg) skewY(-60deg);
        .text {
          background: hsla(141, 64%, 30%, 1);
          background: linear-gradient(
            90deg,
            hsla(141, 64%, 30%, 1) 0%,
            hsla(138, 60%, 35%, 1) 100%
          );
          background: -moz-linear-gradient(
            90deg,
            hsla(141, 64%, 30%, 1) 0%,
            hsla(138, 60%, 35%, 1) 100%
          );
          background: -webkit-linear-gradient(
            90deg,
            hsla(141, 64%, 30%, 1) 0%,
            hsla(138, 60%, 35%, 1) 100%
          );
        }
        .image {
          transform: rotate(28deg) skewY(35deg);
          top: 130px;
          left: 12px;
          width: 70px;
          height: 26px;
        }
      }
      &:nth-child(11) {
        transform: rotate(300deg) skewY(-60deg);
        .text {
          background: hsla(210, 55%, 65%, 1);
          background: linear-gradient(90deg, #75a6d7 0%, #7dacda 100%);
          background: -moz-linear-gradient(90deg, #75a6d7 0%, #7dacda 100%);
          background: -webkit-linear-gradient(90deg, #75a6d7 0%, #7dacda 100%);
        }
        .image {
          transform: rotate(14deg) skewY(50deg);
          left: 22px;
          top: 138px;
          width: 59px;
          height: 30px;
        }
      }
      &:nth-child(12) {
        transform: rotate(330deg) skewY(-60deg);
        .text {
          background: hsla(211, 71%, 41%, 1);
          background: linear-gradient(
            90deg,
            hsla(211, 71%, 41%, 1) 0%,
            hsla(214, 50%, 48%, 1) 100%
          );
          background: -moz-linear-gradient(
            90deg,
            hsla(211, 71%, 41%, 1) 0%,
            hsla(214, 50%, 48%, 1) 100%
          );
          background: -webkit-linear-gradient(
            90deg,
            hsla(211, 71%, 41%, 1) 0%,
            hsla(214, 50%, 48%, 1) 100%
          );
        }
        .image {
          transform: rotate(7deg) skewY(55deg);
          top: 130px;
          left: 27px;
          width: 46px;
          height: 30px;
        }
      }
      .image {
        position: absolute;
        width: 40px;
        height: 26px;
        transform: rotate(310deg) skewY(-239deg);
        top: 118px;
        left: 18px;
      }
    }
  }
  .spin-center-second {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 240px;
    height: 240px;
    display: flex;
    justify-content: center;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    img {
      width: 100%;
      height: 100%;
    }
  }
}
